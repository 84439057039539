import React, { useState, useEffect, useContext } from "react";
import axios from "../../api/api.js"
import moment from "moment-timezone";
import { AuthContext } from "../../auth/AuthProvider.js";
import { Row, Col, Form, Button } from "react-bootstrap";
import "../../styles/app.css";
import { showToastFailSaveHeader } from "../utils/utilsModalDialog.js";
import { getDetailGLAOUT, getHeaderGLAOUT, getMasterItemID } from "../utils/utilsGetData.js";
import { useLocation } from "react-router-dom";


export function TrxOutSubWH(){
    const userLogin     = useContext(AuthContext);
    
    // state definition
    const [listItem, setListItem]               = useState([]);
    const [opStatus, setOpStatus]               = useState('NEW');
    const [docStatus, setDocStatus]             = useState('');
    const [docHeader, setDocHeader]             = useState({ CustomsDocType:'', CustomsDocID:'',CustomsDocDate:'',DocumentID:'',DocumentDate:'', CreateBy:'', CreateDate:''});
    const [docDetail, setDocDetail]             = useState([]);
    const [inputItem, setInputItem]             = useState({});
    const [disableDocID, setDisableDocID]       = useState(false);
    
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const docID = useQuery().get('docno');
    
    
    const fetchHeader = async(DocumentID) => {
        try{
            const data = await getHeaderGLAOUT(DocumentID);
            setDocHeader(data[0]);
        } catch(err){
            console.error(err);
        }
    };
                
    const fetchDetail = async(DocumentID) => {
        try{
            const data = await getDetailGLAOUT(DocumentID);
            setDocDetail(data);
        } catch(err){
            console.error(err);
        }
    };
    
    
    useEffect(() => {  
        
        if(docID !== null){
            setOpStatus('UPDATE');
            setDocStatus('SAVED');
            setDisableDocID(true);
            fetchHeader(docID);
            fetchDetail(docID);
        } else {
            setOpStatus('NEW');
            setDisableDocID(false);
        }

        const fetchDataMaster = async () => {
            try {
                const getItemID         = await getMasterItemID('all');
                setListItem(getItemID.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataMaster();
        
    }, []);
    
    
    const ocDocHeader = (event) => {
        const { name, value } = event.target;
        setDocHeader(prevState => ({
            ...prevState,
            [name]: value,
            CreateBy: userLogin.value.userName,
            CreateDate: moment().format("YYYY-MM-DD hh:mm:ss")
        }));
    };

    const ocDocDetail = (event) => {
        const { name, value } = event.target;
        setInputItem(prevState => ({
            ...prevState,
            DocumentID: docHeader.DocumentID,
            DocumentDate: docHeader.DocumentDate,
            [name]: value
        }));
    };

    const saveDocHeader = (event) => {
        event.preventDefault();
        if(opStatus==='NEW'){
            axios
            .post('/api/subwh/out/new-header', {dataSubWH: docHeader})
            .then((response) => { if (response.status === 200) { setDocStatus('SAVED')}})
            .catch((error) => { if (error.response) return showToastFailSaveHeader() });
        } else if(opStatus==='UPDATE'){
            axios
            .post('/api/subwh/out/update-header', {dataSubWH: docHeader})
            .then((response) => { if (response.status === 200) { setDocStatus('SAVED')}})
            .catch((error) => { if (error.response) return showToastFailSaveHeader() });
        }
        
    }

    const saveDocDetail = async(event) => {
        event.preventDefault();
        await axios
        .post('/api/subwh/out/detail/new', {dataSubWHDetail: inputItem})
        .then((response) => { if (response.status === 200) { 
            fetchDetail(docHeader.DocumentID);
        }})
        .catch((error) => { if (error.response) return showToastFailSaveHeader() });
    }

    const ocBtnDelItem = (event, TrxID) => {
        event.preventDefault();
        axios
        .post('/api/subwh/out/delete-detail', {dataTrxID: TrxID})
        .then((response) => { if (response.status === 200) { fetchDetail(docHeader.DocumentID)}})
        .catch((error) => { if (error.response) return showToastFailSaveHeader() });
    }

    useEffect(() => {
        
    }, []);

    return(
        <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid px-2">
                        <Row>
                            <Col sm={12} lg={12}>
                                <br/>
                                <h5 className="m-0">TRANSAKSI DATA - PENGELUARAN GUDANG LUAR AREA </h5>
                                <hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <Form>
                                    <Row>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputCustomsDocType" className="form-label font-xsmall">Jenis Dokumen PPB</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="CustomsDocType" id="inputCustomsDocType" value={"PPB-KB"} onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputCustomsDocID" className="form-label font-xsmall">No Dokumen PPB</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="CustomsDocID" id="inputCustomsDocID" value={docHeader.CustomsDocID} onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputCustomsDocDate" className="form-label font-xsmall">Tanggal Dokumen PPB</label>
                                            <input type="date" className="form-control form-control-sm font-xsmall" name="CustomsDocDate" id="inputCustomsDocDate" value={docHeader.CustomsDocDate} onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputDocumentID" className="form-label font-xsmall">Nomor Dokumen LPB</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="DocumentID" id="inputDocumentID" disabled={disableDocID} value={docHeader.DocumentID} onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputDocumentDate" className="form-label font-xsmall">Tanggal Dokumen</label>
                                            <input type="date" className="form-control form-control-sm font-xsmall" name="DocumentDate" id="inputDocumentDate" value={docHeader.DocumentDate} onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputPackingSlipID" className="form-label font-xsmall">Surat Jalan</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="PackingSlipID" id="inputPackingSlipID" value={docHeader.PackingSlipID} onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputSourceWarehouse" className="form-label font-xsmall">Gudang Asal</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="WarehouseSource" id="inputSourceWarehouse" value={docHeader.WarehouseSource} onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputDestinationWarehouse" className="form-label font-xsmall">Gudang Tujuan</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="WarehouseDestination" id="inputDestinationWarehouse" value={docHeader.WarehouseDestination} onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputRemark" className="form-label font-xsmall">Remark / Catatan</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="Remark" id="inputRemark" value={docHeader.Remark} onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={3} xl={2} className="px-1 py-0 form-group">
                                            <br/>
                                            <Button className="btn btn-success font-small" onClick={saveDocHeader}><i className="right fas fa-save"></i></Button>&nbsp;
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <hr/>
                                <p><strong>Tambah Detail Material</strong></p>                            
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <Form>
                                    <Row>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputItemID" className="form-label font-xsmall">Item ID</label>
                                            <input type="text" list="listItem" className="form-control form-control-sm font-xsmall" name="ItemID" id="inputItemID" onChange={ocDocDetail} required disabled={docStatus !== 'SAVED'} ></input>
                                            <datalist id="listItem">
                                                {listItem.map((item, index) => (
                                                    <option key={item.ItemID} value={item.ItemID} >{item.ItemID} - {item.ItemDescription}</option> 
                                                ))
                                                }
                                            </datalist>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputItemColor" className="form-label font-xsmall">Warna</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputItemColor" name="ItemColor" onChange={ocDocDetail} disabled={docStatus !== 'SAVED'} style={{textTransform:'uppercase'}}></input>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputItemSize" className="form-label font-xsmall">Ukuran</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputItemSize" name="ItemSize" onChange={ocDocDetail} disabled={docStatus !== 'SAVED'} style={{textTransform:'uppercase'}}></input>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputItemSerial" className="form-label font-xsmall">Serial</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputItemSerial" name="ItemSerial" onChange={ocDocDetail} disabled={docStatus !== 'SAVED'} style={{textTransform:'uppercase'}}></input>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputUOM" className="form-label font-xsmall">UOM</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputUOM" name="UOM" maxLength={"3"} onChange={ocDocDetail} required disabled={docStatus !== 'SAVED'} style={{textTransform:'uppercase'}}></input>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputQty" className="form-label font-xsmall">Jumlah</label>
                                            <input type="number" step={'0.00'} className="form-control form-control-sm font-xsmall" name="Qty" id="inputQty" onChange={ocDocDetail} required disabled={docStatus !== 'SAVED'}></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 form-group">
                                            <br/>
                                            <button type="button" onClick={saveDocDetail} className="btn btn-success"  disabled={docStatus !== 'SAVED'}><i className="right fas fa-plus"></i></button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr className="bg-primary shadow" style={{fontSize: "x-small"}}>
                                            <th>Kode Barang</th>
                                            <th>Deskripsi</th>
                                            <th>Warna</th>
                                            <th>Ukuran</th>
                                            <th>Serial</th>
                                            <th>Satuan</th>
                                            <th>Jumlah</th>
                                            <th>Opsi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {docDetail.map((item, index) => (
                                        <tr key={index} style={{fontSize: "x-small"}}>
                                            <th>{item.ItemID}</th>
                                            <th>{item.ItemDescription}</th>
                                            <th>{item.ItemColor}</th>
                                            <th>{item.ItemSize}</th>
                                            <th>{item.ItemSerial}</th>
                                            <th>{item.UOM}</th>                                           
                                            <th>{item.Qty}</th>
                                            <td><button type="button" className="btn btn-danger btn-sm" onClick={(event) => ocBtnDelItem(event, item.TrxID)}><i className="right fas fa-trash"></i></button></td>
                                        </tr>
                                        ))}
                                        
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
            </div>
        </div>
    )
}