import React, { useEffect, useState, useRef }  from "react";
import { Row, Col } from "react-bootstrap";
import axios from "../../api/api.js"
import moment from "moment-timezone";
import { AgGridReact } from "ag-grid-react";
import { defaultColDef, colDefInReport } from "../utils/utilsAgGrid.js";
import { renewPin } from "../utils/utilsCalculate.js";
import { columnListInReport, generateExcel } from "../utils/utilsExportXLS.js";
import FileSaver from "file-saver";
import Swal from "sweetalert2";
import CompDateRangeWeek from "../utils/utilsDateSelector.js";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional theme CSS
import "../../styles/app.css";

export function ReportPemasukan({history}){
    const [dateSelection, setDateSelection]             = useState({ start: moment(), end: moment()});
    const [dataPemasukan, setDataPemasukan]             = useState([]);
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([renewPin(dataPemasukan)]);
    const gridRef = useRef();
    
    async function fetchData(FilterStartDate, FilterEndDate) {
        Swal.fire({
            title: "Memproses Data..",
            didOpen: async () => {
                Swal.showLoading();
                await axios
                    .get(`/api/pemasukan-barang/${FilterStartDate}/${FilterEndDate}`)
                    .then((response) => {
                        if (response.status === 200) {
                            setDataPemasukan(response.data.data);
                            Swal.close();
                        }
                    })
                    .catch((error) => {
                        Swal.close();
                        if (error.response) return console.log("Error get Data");
                    });
            },
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) console.log("I was closed by the timer");
        });
    }
    
    function handleChangeDate(start, end) {
        setDateSelection({ start, end });
        fetchData(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
    }
    
    function refreshPinned(gridRef, dataResultScan) {
        if (gridRef.current.api) {
            setPinnedBottomRowData([renewPin(dataResultScan)]);
        }
    }
  

    function onfiltered(e) {
        const isFilterActive = e.api.isAnyFilterPresent();
        if (isFilterActive) {
            let dataRender = [];
            e.api.forEachNodeAfterFilter(function (node) {
                dataRender.push(node.data);
            });
            return refreshPinned(gridRef, dataRender);
        } else {
            return refreshPinned(gridRef, dataPemasukan);
        }
    }

    
    
    const handleButtonDownloadXLS = async (event) => {
        event.preventDefault();
        try{
            const sheetName     = "Laporan Pemasukan Barang"
            const buffer        = await generateExcel(sheetName, columnListInReport, dataPemasukan);
            const blob          = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `LAPORAN-PEMASUKAN-${moment(dateSelection.start).format('YYYYMMDD')}-${moment(dateSelection.end).format('YYYYMMDD')}.xlsx`);
        } catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        if (gridRef) {
            setPinnedBottomRowData([renewPin(dataPemasukan)]);
        }
    }, [dataPemasukan, gridRef]);

    useEffect(() => {
        const dateNow   = moment();
        const date7day  = moment().subtract(7, 'days'); 
        fetchData(date7day.format('YYYY-MM-DD'), dateNow.format("YYYY-MM-DD"));
        setDateSelection({ start: date7day, end:dateNow });
    }, []);

    return(
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid px-2">
                        <Row>
                            < Col sm={12} lg={12}>
                                <br/>
                                <h5 className="m-0">Laporan Pemasukan Barang per Dokumen Pabean  </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12} className="pt-2 pt-lg-0">
                                <Row>
                                    <Col sm={8} md={5} lg={3} className="form-group mb-2 mb-lg-0">
                                        <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                        <CompDateRangeWeek state={dateSelection} handleCallback={handleChangeDate} style={{width: "100%"}} /> 
                                    </Col>
                                    <Col sm={0} md={6} lg={8} className="form-group mb-2 mb-lg-0">
                                        &nbsp;
                                    </Col>
                                    <Col sm={4} md={1} lg={1} className="form-group mb-2 mb-lg-0 text-right">
                                        <br/>
                                        <button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadXLS} ><i className="right fas fa-download"></i></button>
                                    </Col>
                                </Row>
                            </Col>    
                        </Row>
                        <Row>
                            <Col className="pt-2 pt-lg-2">
                                <div className="ag-theme-quartz" style={{ height: "80vh", width: "100%", fontSize: "x-small" }} >
                                    <AgGridReact 
                                        ref={gridRef}
                                        onFilterChanged={(e) => onfiltered(e)}
                                        columnDefs={colDefInReport} 
                                        rowData={dataPemasukan} 
                                        defaultColDef={defaultColDef} 
                                        pagination={'true'} 
                                        pinnedBottomRowData={pinnedBottomRowData}
                                    >
                                    </AgGridReact>
                                </div>
                                <br/>
                            </Col>
                        </Row>
                    </div>
            </div>
        </div>       
    )
}