import moment from "moment";

export const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    //floatingFilter: true,
    suppressSizeToFit: true,
    resizable: true,
    flex: 1
};


export const localeTextFunc = (key, defaultValue) => {
    const translations = {
      page: 'Halaman',
      more: 'Lainnya',
      to: 'ke'
      // ...other translations
    };
  
    return translations[key] || defaultValue;
};


export const colDefInOut = [
    {   headerName: "Dokumen Pabean",       
        children: [
            { headerName: "Jenis", field: "CustomsDocType"},
            { headerName: "Nomor", field: "CustomsDocID"},
            { headerName: "Tanggal", field: "CustomsDocDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD")},
        ],
        headerClass: "text-center"
    },
    {   headerName: "Bukti Penerimaan Barang", 
        children: [
            { headerName: "No Dokumen", field: "DocumentID" },
            { headerName: "Tanggal Dokumen", field: "DocumentDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD") },
        ],
    },
    { headerName: "Supplier / Vendor", field: "VendorName"},
    { headerName: "Kode Barang", field: "ItemID" },
    { headerName: "Nama Barang", field: "ItemDescription", cellStyle: { userSelect: 'text' }},
    { headerName: "Satuan", field: "UOM" },
    { headerName: "Jumlah", field: "Qty", cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2) },
    { headerName: "Mata Uang", field: "Currency" },
    { headerName: "Nilai", field: "Value", cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(4) },
];

export const colDefInReport = [
    {   headerName: "Dokumen Pabean",       
        children: [
            { headerName: "Jenis", field: "CustomsDocType", cellStyle: { textAlign: "center" }},
            { headerName: "Nomor", field: "CustomsDocID", cellStyle: { textAlign: "center" }},
            { headerName: "Tanggal", field: "CustomsDocDate", cellStyle: { textAlign: "center" }},
        ],
        headerClass: [ 'text-center']
    },
    {   headerName: "Bukti Pemasukan Barang", 
        children: [
            { headerName: "No Dokumen", field: "DocumentID", cellStyle: { textAlign: "center" } },
            { headerName: "Tanggal Dokumen", field: "DocumentDate", cellStyle: { textAlign: "center" } },
        ],
        headerClass: [ 'text-center']
    },
    { headerName: "Pengirim Barang", field: "VendorName"},
    { headerName: "Kode Barang", field: "ItemID", cellStyle: { textAlign: "center" } },
    { headerName: "Nama Barang", field: "ItemDescription"},
    { headerName: "Satuan", field: "UOM", cellStyle: { textAlign: "center" } },
    { headerName: "Jumlah", field: "Qty", cellStyle: { textAlign: "center" }, cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2) },
    { headerName: "Mata Uang", field: "Currency", cellStyle: { textAlign: "center" } },
    { headerName: "Nilai", field: "Value", cellStyle: { textAlign: "center" }, cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(6) },
];

export const colDefOutReport = [
    {   headerName: "Dokumen Pabean",       
        children: [
            { headerName: "Jenis", field: "CustomsDocType", cellStyle: { textAlign: "center" }},
            { headerName: "Nomor", field: "CustomsDocID", cellStyle: { textAlign: "center" }},
            { headerName: "Tanggal", field: "CustomsDocDate", cellStyle: { textAlign: "center" }},
        ],
        headerClass: "text-center"
    },
    {   headerName: "Bukti Pengeluaran Barang", 
        children: [
            { headerName: "No Dokumen", field: "DocumentID", cellStyle: { textAlign: "center" } },
            { headerName: "Tanggal Dokumen", field: "DocumentDate", cellStyle: { textAlign: "center" } },
        ],
    },
    { headerName: "Penerima Barang", field: "VendorName"},
    { headerName: "Kode Barang", field: "ItemID", cellStyle: { textAlign: "center" } },
    { headerName: "Nama Barang", field: "ItemDescription"},
    { headerName: "Satuan", field: "UOM", cellStyle: { textAlign: "center" } },
    { headerName: "Jumlah", field: "Qty", cellStyle: { textAlign: "center" }, cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2) },
    { headerName: "Mata Uang", field: "Currency", cellStyle: { textAlign: "center" } },
    { headerName: "Nilai", field: "Value", cellStyle: { textAlign: "center" }, cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(6) },
];



export const colDefWIP = [
    { headerName: "Kode Barang", field: "ItemID" },
    { headerName: "Nama Barang", field: "ItemDescription", cellClass: [ 'text-left']  },
    { headerName: "Satuan", field: "UOM"},
    { headerName: "Saldo Akhir", field: "SaldoWIPAkhir", cellRenderer: (params) => params.value === null ? '': parseInt(params.value) }
];

export const colDefMutasi = [
    { headerName: "Kode Barang", field: "ItemID" },
    { headerName: "Nama Barang", field: "ItemDescription" },
    { headerName: "Satuan", field: "UOM" },
    { headerName: "Saldo Awal", field: "SaldoAwal", cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2) },
    { headerName: "Pemasukan", field: "SaldoMasuk", cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2) },
    { headerName: "Pengeluaran", field: "SaldoKeluar", cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2) },
    { headerName: "Penyesuaian", field: "SaldoAdjustment", cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2)},
    { headerName: "Saldo Buku", field: "SaldoAkhir", cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2) },
    { headerName: "Stock Opname", field: "SaldoStockOpname", cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2) },
    { headerName: "Selisih", field: "SaldoSelisih", cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2)},
    { headerName: "Keterangan", field: "Keterangan"}
];

export const colDefTraceGRN = [
    {   headerName: "Dokumen Pabean",       
        children: [
            { headerName: "Jenis", field: "CustomDocTypeCode"},
            { headerName: "Nomor", field: "CustomDocNo"},
            { headerName: "Tanggal", field: "CustomDocDate"},
        ],
        headerClass: "text-center"
    },
    { headerName: "GRN ID", field: "GRNID" },
    { headerName: "Inhouse Date", field: "InhouseDate" },
    {   headerName: "Material / Barang",       
        children: [
            { headerName: "Item ID", field: "ItemID" },
            { headerName: "Item Description", field: "ItemDescription" },
        ],
        headerClass: "text-center"
    },
    { headerName: "UOM", field: "UOM" },
    { headerName: "Total GRN", field: "GRNQty" },
    { headerName: "Total GIN", field: "GINQty" },
    { headerName: "Total MRV", field: "MRVQty" },
    { headerName: "Total MRR", field: "MRRQty" },
    { headerName: "Total MRS", field: "MRSQty" },
    { headerName: "Variance Qty", field: "SumVarQty" },
    { headerName: "Percentage %", field: "SumVarPercent", cellRenderer: (params) => params.value + "%"},
];

export const colDefTraceOrder = [
    { headerName: "Order ID", field: "OrderID" },
    { headerName: "Item ID", field: "ItemID" },
    { headerName: "Item Description", field: "ItemDescription" },
    { headerName: "UOM", field: "UOM" },
    { headerName: "BOM Required Qty", field: "BOMRequiredQty" },
    { headerName: "BOM Approved Purchase", field: "BOMApprovedPO" },
    { headerName: "BOM Allocated Qty", field: "BOMAllocatedQty" },
    { headerName: "MRP", field: "MRPQty" },
    { headerName: "MRN", field: "MRNQty" },
    { headerName: "GRN", field: "GRNQty" },
    { headerName: "GIN", field: "GINQty" },
    { headerName: "MRS", field: "MRSQty" },
    { headerName: "MRV", field: "MRVQty" },
    { headerName: "MRR", field: "MRRQty" },
    { headerName: "Variance Qty", field: "SumVarQty" },
    { headerName: "Percentage %", field: "SumVarPercent", cellRenderer: (params) => params.value + "%"}
];

export const colDefLogMaterialInbound = [
    {   headerName: "Dokumen Pabean",       
        children: [
            { headerName: "Jenis", field: "CustomsDocType"},
            { headerName: "Nomor", field: "CustomsDocID"},
            { headerName: "Tanggal", field: "CustomsDocDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD")},
        ],
        headerClass: "text-center"
    },
    {   headerName: "Bukti Penerimaan Barang", 
        children: [
            { headerName: "Jenis Dokumen", field: "DocumentType" },
            { headerName: "No Dokumen", field: "DocumentID" },
            { headerName: "Tanggal Dokumen", field: "DocumentDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD") },
        ],
    },
    { headerName: "Vendor / Supplier", field: "VendorName"},
    { headerName: "Create By", field: "CreateBy"},
    { headerName: "Create Date", field: "CreateDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD hh:mm:ss")},
    { headerName: "Update By", field: "UpdateBy"},
    { headerName: "Update Date", field: "UpdateDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD hh:mm:ss")},
];

export const colDefLogMaterialOutbound = [
    {   headerName: "Dokumen Pabean",       
        children: [
            { headerName: "Jenis", field: "CustomsDocType"},
            { headerName: "Nomor", field: "CustomsDocID"},
            { headerName: "Tanggal", field: "CustomsDocDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD")},
        ],
        headerClass: "text-center"
    },
    {   headerName: "Bukti Penerimaan Barang", 
        children: [
            { headerName: "Jenis Dokumen", field: "DocumentType" },
            { headerName: "No Dokumen", field: "DocumentID" },
            { headerName: "Tanggal Dokumen", field: "DocumentDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD") },
        ],
    },
    { headerName: "Vendor / Customer", field: "VendorName"},
    { headerName: "Create By", field: "CreateBy"},
    { headerName: "Create Date", field: "CreateDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD hh:mm:ss")},
    { headerName: "Update By", field: "UpdateBy"},
    { headerName: "Update Date", field: "UpdateDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD hh:mm:ss")},
];

export const colDefLogProdIN = [
    { headerName: "No Dokumen", field: "DocumentID"},
    { headerName: "Tanggal Dokumen", field: "DocumentDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD")},
    { headerName: "Customer", field: "CustomerName"},
    { headerName: "Order PO", field: "POID"},
    { headerName: "Manufacture Order", field: "MOID"},
    { headerName: "Item ID", field: "ItemID"},
    { headerName: "Item Description", field: "ItemDescription"},
    { headerName: "UOM", field: "UOM"},
    { headerName: "Qty", field: "Qty"},
    { headerName: "Create By", field: "CreateBy"},
    { headerName: "Create Date", field: "CreateDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD hh:mm:ss")},
];

export const colDefLogGLA = [
    {   headerName: "Dokumen Pabean",       
        children: [
            { headerName: "Jenis", field: "CustomsDocType"},
            { headerName: "Nomor", field: "CustomsDocID"},
            { headerName: "Tanggal", field: "CustomsDocDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD")},
        ],
        headerClass: "text-center"
    },
    {   headerName: "Bukti Penerimaan Barang", 
        children: [
            { headerName: "No Dokumen", field: "DocumentID" },
            { headerName: "Tanggal Dokumen", field: "DocumentDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD") },
        ],
    },
    { headerName: "Gudang Tujuan", field: "WarehouseDestination"},
    { headerName: "Create By", field: "CreateBy"},
    { headerName: "Create Date", field: "CreateDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD hh:mm:ss")},
    { headerName: "Update By", field: "UpdateBy"},
    { headerName: "Update Date", field: "UpdateDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD hh:mm:ss")},
];

export const colItemInbound = [
    { headerName: "MPO", field:"MPOID" },
    { headerName: "Item ID", field: "ItemID"},
    { headerName: "Deskripsi", field: "ItemDescription"},
    { headerName: "Warna", field: "ItemColor"},
    { headerName: "Ukuran", field: "Itemsize"},
    { headerName: "Serial", field: "ItemSerial"},
    { headerName: "Satuan", field: "UOM"},
    { headerName: "Jumlah", field: "Qty"},
    { headerName: "Mata Uang", field: "Currency"},
    { headerName: "Nilai", 
        valueGetter: params => {
            const unitPrice = params.data.UnitPrice;
            const quantity = params.data.Qty;
            if (unitPrice && quantity) {
                return unitPrice * quantity;
            }
            return null;
        },
        valueFormatter: params => {
            if (params.value != null) {
                return parseFloat(params.value).toFixed(2);
            }
            return null;
        }
    },
];

export const colItemOutbound = [
    { headerName: "PO", field:"POID" },
    { headerName: "MO / JO", field: "MOID"},
    { headerName:"Item ID" , field:"ItemID" },
    { headerName:"Deskripsi" , field:"ItemDescription" },
    { headerName:"Warna" , field:"ItemColor" },
    { headerName:"Serial" , field:"ItemSerial" },
    { headerName:"Satuan" , field:"UOM" },
    { headerName:"Jumlah" , field:"Qty" },
    { headerName:"Mata Uang" , field:"Currency" },
    { headerName: "Nilai", 
        valueGetter: params => {
            const unitPrice = params.data.UnitPrice;
            const quantity = params.data.Qty;
            if (unitPrice && quantity) {
                return unitPrice * quantity;
            }
            return null;
        },
        valueFormatter: params => {
            if (params.value != null) {
                return parseFloat(params.value).toFixed(2);
            }
            return null;
        }
    }
];


// Sub Warehouse 
export const colDefSubWH = [
    {   headerName: "Dokumen Pabean",       
        children: [
            { headerName: "Jenis / Nomor / Tanggal", field: "PPBDocID"},
        ],
        headerClass: "text-center"
    },
    {   headerName: "Bukti Pemasukan Barang", 
        children: [
            { headerName: "No Dokumen", field: "DocumentID" },
            { headerName: "Tanggal Dokumen", field: "DocumentDate" },
        ],
    },
    { headerName: "Gudang Tujuan", field: "WarehouseDestination"},
    { headerName: "Kode Barang", field: "ItemID" },
    { headerName: "Nama Barang", field: "ItemDescription"},
    { headerName: "Satuan", field: "UOM" },
    { headerName: "Jumlah", field: "Qty"}
];

export const colDefInOutSubWH = [
    {   headerName: "Dokumen Pabean",       
        children: [
            { headerName: "Jenis / Nomor / Tanggal", field: "PPBDocID"}
        ],
        headerClass: "text-center"
    },
    {   headerName: "Bukti Penerimaan Barang", 
        children: [
            { headerName: "No Dokumen", field: "DocumentID" },
            { headerName: "Tanggal Dokumen", field: "DocumentDate", cellRenderer: (params) => params.value === null ? '': moment(params.value).format("YYYY-MM-DD") },
        ],
    },
    { headerName: "Lokasi Tujuan", field: "WHLocation"},
    { headerName: "Kode Barang", field: "ItemID" },
    { headerName: "Nama Barang", field: "ItemDescription"},
    { headerName: "Satuan", field: "UOM" },
    { headerName: "Jumlah", field: "Qty" }
];

export const colDetailModalGLA = [
    { headerName: "Item ID", field: "ItemID"},
    { headerName: "Deskripsi", field: "ItemDescription"},
    { headerName: "Warna", field: "ItemColor"},
    { headerName: "Ukuran", field: "Itemsize"},
    { headerName: "Serial", field: "ItemSerial"},
    { headerName: "Satuan", field: "UOM"},
    { headerName: "Jumlah", field: "Qty"}
];

export const colDefMasterVendor = [
    { headerName:"Vendor ID", field: "VendorID"},
    { headerName:"Vendor Name", field: "VendorName"},
    { headerName:"Country Origin", field: "Origin"},
];

export const colDefMasterItem = [
    { headerName:"Item Type Code", field: "ItemTypeCode"},
    { headerName:"Item Category Code", field: "ItemCategoryCode"},
    { headerName:"Item ID", field:"ItemID"},
    { headerName: "Item Description", field: "ItemDescription" },
];

export const colDefStockCard = [
    { headerName: "Tipe Item", field: "ItemType" },
    { headerName: "Tanggal", field: "DocumentDate" },
    { headerName: "No Dokumen", field: "DocumentID" },
    { headerName: "PO", field: "PO"},
    { headerName: "MO", field: "MO"},
    { headerName: "Item ID", field: "ItemID" },
    { headerName: "Item Description", field:"ItemDescription" },
    { headerName: "Item Serial", field:"ItemSerial"},
    { headerName: "Qty IN", field:"QtyIN", cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2)  },
    { headerName: "Qty OUT", field:"QtyOUT", cellRenderer: (params) => params.value === null ? '': parseFloat(params.value).toFixed(2)  }
];

export const colDefFGMonitoring = [
    { headerName: "ItemID", field: "ItemID" },
    { headerName: "ItemDescription", field: "ItemDescription"},
    { headerName: "UOM", field: "UOM"},
    { headerName: "FG Impor", field: "FGImport"},
    { headerName: "IN Produksi", field: "FGProdStart"},
    { headerName: "OUT Produksi", field: "FGProdFinish"},
    { headerName: "Varian Produksi", field: "VarProd"},
    { headerName: "FG Export", field: "FGExport"},
    { headerName: "FG Balance", field: "FGBalance"},
];

