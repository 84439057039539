import { useContext } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "./AuthProvider";


const ProtectedRouter = ({ children }) => {
    const navigate          = useNavigate();  
    const { value }         = useContext(AuthContext);
    const { token, menus }  = value;
    const location          = useLocation();
    const checkAccessPath   = menus.filter(
      (menu) => `/${menu.menuPath}` === location.pathname
    );
    
    if (!token) return <Navigate to="/" />;
    if (!checkAccessPath || checkAccessPath.length===0) return navigate(-1);
    return children;
  };
  
  export default ProtectedRouter;
  