import axios from "../../api/api.js";

export const formatArrHeaderDoc = {
    CustomsDocType: '',
    CustomsDocID: '', 
    CustomsDocDate: '', 
    DocumentID: '', 
    DocumentDate: '', 
    VendorName: '', 
    DocumentType: '', 
    Origin:'', 
    CreateBy: '', 
    CreateDate:'', 
    UpdateBy:'', 
    UpdateDate:'' 
};

export const formatArrDetailDoc = [
    { 
        CustomsDocType: '', 
        CustomsDocID: '', 
        CustomsDocDate: '', 
        DocumentID: '', 
        DocumentDate: '', 
        VendorName: '', 
        DocumentType: '', 
        Origin:'', 
        CreateBy: '', 
        CreateDate:'', 
        UpdateBy:'', 
        UpdateDate:'' 
    }
];

export async function getMasterVendor(){
    const getVendorID = await axios.get("/api/data/vendor");
    return getVendorID.data.data;
}

export async function getDataUser(){
    const getUserID = await axios.get("/api/users");
    return getUserID.data.data;
};

export async function getMasterTrxIN(){
    const getTrxIN = await axios.get('/api/data/transaction/all/in');
    return getTrxIN.data.data;
}

export async function getMasterTrxOUT(){
    const getTrxOUT = await axios.get('/api/data/transaction/all/out');
    return getTrxOUT.data.data;
}


export async function getMasterItem(){
    const getItem = await axios.get(`/api/data/item/all`);
    return getItem.data.data;
};

export async function getMasterItemID(type){
    const getItem = await axios.get(`/api/data/item/${type}`);
    return getItem.data.data;
};


export async function getListItemCategory(ItemTypeCode){
    const itemType          = ItemTypeCode || 'MATERIAL';
    const getItemCategory   = await axios.get(`/api/data/item-category/${itemType}`);
    return getItemCategory.data.data;
};

export async function getCustomsDoc(){
    const getCustomsDoc     = await axios.get("/api/data/customsdoc");
    return getCustomsDoc.data.data;
}

export async function getDocHeaderIN(DocumentID){
    await axios
    .get(`/api/inbound/header/${encodeURIComponent(DocumentID)}`)
    .then((response) => { 
        if(response.status === 200) return response.data.data[0] })
    .catch((error) => { if (error.response) return console.log("Error get Data")});
}

export async function getDocDetailIN(DocumentID){
    await axios
    .get(`/api/inbound/detail/${encodeURIComponent(DocumentID)}`)
    .then((response) => { 
        if (response.status === 200) { 
            return response.data.data;
        }})
    .catch((error) => { if (error.response) return console.log("Error get Data")});
}

export async function getHeaderGLAIN(DocumentID) {
    try {
        const response = await axios.get(`/api/subwh/in-header-docs/${encodeURIComponent(DocumentID)}`);       
        if (response.status === 200) {
            return response.data.data;
        } else {
            console.error("Unexpected response status:", response.status);
            return null; 
        }
    } catch (error) {
        console.error("Error getting data:", error);
        return null; 
    }
}

export async function getDetailGLAIN(DocumentID) {
    try {
        const response = await axios.get(`/api/subwh/in-detail-docs/${encodeURIComponent(DocumentID)}`);       
        if (response.status === 200) {
            return response.data.data; // This ensures data is returned correctly
        } else {
            console.error("Unexpected response status:", response.status);
            return null; // Handle unexpected status codes
        }
    } catch (error) {
        console.error("Error getting data:", error);
        return null; // Return null or handle the error appropriately
    }
}


export async function getHeaderGLAOUT(DocumentID) {
    try {
        const response = await axios.get(`/api/subwh/out-header-docs/${encodeURIComponent(DocumentID)}`);       
        if (response.status === 200) {
            return response.data.data; 
        } else {
            console.error("Unexpected response status:", response.status);
            return null; 
        }
    } catch (error) {
        console.error("Error getting data:", error);
        return null; 
    }
}

export async function getDetailGLAOUT(DocumentID) {
    try {
        const response = await axios.get(`/api/subwh/out-detail-docs/${encodeURIComponent(DocumentID)}`);       
        if (response.status === 200) {
            return response.data.data; 
        } else {
            console.error("Unexpected response status:", response.status);
            return null; 
        }
    } catch (error) {
        console.error("Error getting data:", error);
        return null;
    }
}


export const removeObjectsWithNullValues = (array) => {
    return array.filter(obj => {
        return !Object.values(obj).includes(null);
    });
};


// Function to filter the object
export const filterObject = obj => {
    const filteredObj = {};
    for (const key in obj) {
      if (obj[key] !== null) {
        filteredObj[key] = obj[key];
      }
    }
    return filteredObj;
  };