import React, { useState, useEffect, useContext } from "react";
import axios from "../../api/api.js"
import moment from "moment-timezone";
import { AuthContext } from "../../auth/AuthProvider.js";
import { Row, Col, Form, Button } from "react-bootstrap";
import "../../styles/app.css";
import { showToastFailSaveHeader } from "../utils/utilsModalDialog.js";
import { getMasterItemID } from "../utils/utilsGetData.js";


export function TrxInProd(){
    const userLogin     = useContext(AuthContext);
    
    // state definition
    const [listItem, setListItem]               = useState([]);
    const [docStatus, setDocStatus]             = useState('');
    const [docHeader, setDocHeader]             = useState({ DocumentID:'', DocumentDate:'', CreateBy:'', CreateDate:'', UpdateBy:'', UpdateDate:'' });
    const [docDetail, setDocDetail]             = useState([]);
    const [listDocData, setListDocData]         = useState([]);

    useEffect(() => {
        const fetchDataMaster = async () => {
            try {
                const getItemID         = await getMasterItemID('fg');
                setListItem(getItemID.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataMaster();

    }, []);

    const ocDocHeader = (event) => {
        const { name, value } = event.target;
        setDocHeader(prevState => ({
            ...prevState,
            [name]: value,
            CreateBy: userLogin.value.userName,
            CreateDate: moment().format("YYYY-MM-DD hh:mm:ss")
        }));
    };

    const ocDocDetail = (event) => {
        const { name, value } = event.target;
        setDocDetail(prevState => ({
            ...prevState,
            DocumentID: docHeader.DocumentID,
            [name]: value
        }));
    };

    const saveDocHeader = (event) => {
        event.preventDefault();
        axios
        .post('/api/production/in/header', {docHeader: docHeader})
        .then((response) => { if (response.status === 200) { setDocStatus('SAVED')}})
        .catch((error) => { if (error.response) return showToastFailSaveHeader() });
    }

    const saveDocDetail = (event) => {
        event.preventDefault();
        axios
        .post('/api/production/in/detail', {docDetail: docDetail})
        .then((response) => { if (response.status === 200) { getDocDetail(); }})
        .catch((error) => { if (error.response) return showToastFailSaveHeader() });
        
    }

    const ocBtnDelItem = (event, idLogTrx) => {
        event.preventDefault();
        console.log(idLogTrx);
        axios
        .post('/api/production/in/delete-detail', {idLogTrx: idLogTrx})
        .then((response) => { if (response.status === 200) { getDocDetail()}})
        .catch((error) => { if (error.response) return showToastFailSaveHeader() });
    }


    const getDocDetail = async() => {
        try {
            if(docHeader.DocumentID !== null) {
                const response = await axios.get(`/api/production/detail/in/${encodeURIComponent(docHeader.DocumentID)}`);
                setListDocData(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
        
    }
    
    return(
        <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid px-2">
                        <Row>
                            <Col sm={12} lg={12}>
                                <br/>
                                <h5 className="m-0">TRANSAKSI DATA - PEMASUKAN PRODUKSI </h5>
                                <hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <Form>
                                    <Row>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputDocumentID" className="form-label font-xsmall">Nomor Dokumen LPB</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="DocumentID" id="inputDocumentID" onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 py-0 form-group">
                                            <label htmlFor="inputDocumentDate" className="form-label font-xsmall">Tanggal Dokumen</label>
                                            <input type="date" className="form-control form-control-sm font-xsmall" name="DocumentDate" id="inputDocumentDate" onChange={ocDocHeader} required></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={3} xl={2} className="px-1 py-0 form-group">
                                            <br/>
                                            <Button className="btn btn-success font-small" onClick={saveDocHeader}><i className="right fas fa-save"></i></Button>&nbsp;
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <hr/>
                                <p><strong>Tambah Detail Material</strong></p>                            
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <Form>
                                    <Row>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputPOID" className="form-label font-xsmall">PO</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="POID" id="inputPOID" onChange={ocDocDetail} required disabled={docStatus !== 'SAVED'}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputMOID" className="form-label font-xsmall">MO / JO</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" name="MOID" id="inputPOID" onChange={ocDocDetail} required disabled={docStatus !== 'SAVED'}></input>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputItemID" className="form-label font-xsmall">Item ID</label>
                                            <input type="text" list="listItem" className="form-control form-control-sm font-xsmall" name="ItemID" id="inputItemID" onChange={ocDocDetail} required disabled={docStatus !== 'SAVED'} ></input>
                                            <datalist id="listItem">
                                                {listItem.map((item, index) => (
                                                    <option key={item.ItemID} value={item.ItemID} >{item.ItemID} - {item.ItemDescription}</option> 
                                                ))
                                                }
                                            </datalist>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputUOM" className="form-label font-xsmall">UOM</label>
                                            <input type="text" className="form-control form-control-sm font-xsmall" id="inputUOM" name="UOM" maxLength={"3"} onChange={ocDocDetail} required disabled={docStatus !== 'SAVED'} style={{textTransform:'uppercase'}}></input>
                                        </Col>
                                        <Col sm={6} md={3} lg={2} xl={2} className="px-1 form-group form-group-sm">
                                            <label htmlFor="inputQty" className="form-label font-xsmall">Jumlah</label>
                                            <input type="number" step={'0.00'} className="form-control form-control-sm font-xsmall" name="Qty" id="inputQty" onChange={ocDocDetail} required disabled={docStatus !== 'SAVED'}></input>
                                        </Col>
                                        <Col sm={12} md={3} lg={2} xl={2} className="px-1 form-group">
                                            <br/>
                                            <button type="button" onClick={saveDocDetail} className="btn btn-success"  disabled={docStatus !== 'SAVED'}><i className="right fas fa-plus"></i></button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr className="bg-primary shadow" style={{fontSize: "x-small"}}>
                                            <th>MO</th>
                                            <th>MO</th>
                                            <th>Item ID</th>
                                            <th>Deskripsi</th>
                                            <th>Satuan</th>
                                            <th>Jumlah</th>
                                            <th>Opsi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listDocData.map((item, index) => (
                                        <tr key={index} style={{fontSize: "x-small"}}>
                                            <th>{item.POID}</th>
                                            <th>{item.MOID}</th>
                                            <th>{item.ItemID}</th>
                                            <th>{item.idLogTrx}</th>
                                            <th>{item.UOM}</th>                                           
                                            <th>{item.Qty}</th>
                                            <td><button type="button" className="btn btn-danger btn-sm" onClick={(event) => ocBtnDelItem(event, item.idLogTrx)}><i className="right fas fa-trash"></i></button></td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
            </div>
        </div>
    )
}