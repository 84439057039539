const CheckNilai = (nilai) => {
    if (!nilai || isNaN(nilai)) return 0;
    return nilai;
};

const totalCol = (data, colname) => {
    if (!data.length) return 0;
    return data.reduce(
      (sum, item) =>
        parseFloat(CheckNilai(sum)) + parseFloat(CheckNilai(item[colname])),
      0
    );
};

export function renewPin(data){
    return {
        UOM: "Total",
        Qty: totalCol(data, "Qty").toFixed(2),
        Value: totalCol(data, "Value").toFixed(6)
    }
}

export function renewPinWIP(data){
    return {
        UOM: "Total",
        SaldoWIPAkhir: totalCol(data, "SaldoWIPAkhir"),
    }
}

export function renewPinTrace(data){
    return {
        UOM: "Total",
        BOMRequiredQty: totalCol(data, "BOMRequiredQty"),
        BOMApprovedPO: totalCol(data, "BOMApprovedPO"),
        BOMAllocatedQty: totalCol(data, "BOMAllocatedQty"),
        MRPQty: totalCol(data, "MRPQty"),
        MRNQty: totalCol(data, "MRNQty"),
        GRNQty: totalCol(data, "GRNQty"),
        GINQty: totalCol(data, "GINQty"),
        MRSQty: totalCol(data, "MRSQty"),
        MRVQty: totalCol(data, "MRVQty"),
        MRRQty: totalCol(data, "MRRQty"),
        SumVarQty: totalCol(data, "SumVarQty"),
        SumVarPercent: (( (totalCol(data, "GINQty") - totalCol(data, "MRSQty")) ) / totalCol(data, "GRNQty") * 100).toFixed(2)
    }
}

export function renewPinMutasi(data){
    return {
        UOM: "Total",
        SaldoAwal: totalCol(data, "SaldoAwal").toFixed(2),
        SaldoMasuk: totalCol(data, "SaldoMasuk").toFixed(2),
        SaldoKeluar: totalCol(data, "SaldoKeluar").toFixed(2),
        SaldoAkhir: totalCol(data, "SaldoAkhir").toFixed(2),
        SaldoAdjustment: totalCol(data, "SaldoAdjustment").toFixed(2),
        SaldoStockOpname: totalCol(data, "SaldoStockOpname").toFixed(2),
        SaldoSelisih: totalCol(data, "SaldoSelisih").toFixed(2)
    }
}

export function renewPinStockCard(data){
    return {
        ItemDescription: "Total",
        QtyIN: totalCol(data, "QtyIN").toFixed(2),
        QtyOUT: totalCol(data, "QtyOUT").toFixed(2)
    }
}


export function renewPinModalDocIN(data){
    return {
        UOM: "Total",
        Qty: totalCol(data, "Qty").toFixed(2),
    }
}