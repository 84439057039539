import React, { useEffect, useState, useRef }  from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "../../api/api.js"
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { renewPinWIP } from "../utils/utilsCalculate.js";
import { AgGridReact } from "ag-grid-react";
import { defaultColDef, colDefWIP } from "../utils/utilsAgGrid.js";
import { columnListWIP, generateExcel } from "../utils/utilsExportXLS.js";
import FileSaver from "file-saver";
import CompDateRangeWeek from "../utils/utilsDateSelector.js";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional theme CSS
import "../../styles/app.css";



export function ReportWIP(){
    const [searchStartDate, setSearchStartDate] = useState(moment().subtract(1,'M').format('YYYY-MM-DD'));
    const [searchEndDate, setSearchEndDate]     = useState(moment().format('YYYY-MM-DD'));
    const [dateSelection, setDateSelection]     = useState({ start: moment(), end: moment()});
    const [dataWIP, setDataWIP]                 = useState([]);
    const gridRef                               = useRef();

    function handleChangeDate(start, end) {
        setDateSelection({ start, end });
        fetchData(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
    }
    
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([
        renewPinWIP(dataWIP),
    ]);
    
    //funtion untuk hitung ulang data total
    function refreshPinned(gridRef, dataResultScan) {
        if (gridRef.current.api) setPinnedBottomRowData([renewPinWIP(dataResultScan)]);
    }
  
    useEffect(() => {
        if (gridRef) setPinnedBottomRowData([renewPinWIP(dataWIP)]);
    }, [dataWIP, gridRef]);

      //ini funtion berfungsi ketika ada perubahan pada filter
    function onfiltered(e) {
        const isFilterActive = e.api.isAnyFilterPresent(); //check filter atau tidak
        if (isFilterActive) {
            let dataRender = [];
            e.api.forEachNodeAfterFilter(function (node) {
                dataRender.push(node.data); // Data baris yang lolos dari filter
            });
            return refreshPinned(gridRef, dataRender);
        } else {
            return refreshPinned(gridRef, dataWIP);
        }
    }
    const fetchData = async(FilterStartDate, FilterEndDate) => {
        Swal.fire({
            title: "Please wait..",
            didOpen: async () => {
              Swal.showLoading();
              await axios
                .get(`/api/mutasi/wip/${FilterStartDate}/${FilterEndDate}`)
                .then((response) => {
                  if (response.status === 200) {
                    setDataWIP(response.data.data);
                    Swal.close();
                  }
                })
                .catch((error) => {
                  Swal.close();
                  if (error.response) return console.log(error.response);
                });
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log("I was closed by the timer");
            }
          });
    };

    const handleButtonSearch = (event) => {
        event.preventDefault();
        fetchData(searchStartDate, searchEndDate);
    };
    
    const handleButtonDownloadXLS = async (event) => {
        event.preventDefault();
        try{
            const sheetName     = "Laporan WIP";
            const buffer        = await generateExcel(sheetName, columnListWIP, dataWIP);
            const blob          = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, `LAPORAN-WIP-${moment(searchStartDate).format('YYYYMMDD')}-${moment(searchEndDate).format('YYYYMMDD')}.xlsx`);
        } catch(error){
            console.log(error);
        }
    }
    
    useEffect(() => {
        const dateNow   = moment();
        fetchData(dateNow.format('YYYY-MM-DD'), dateNow.format("YYYY-MM-DD"));
        setDateSelection({ start: dateNow, end:dateNow });
    }, []);

        
    return(
        <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid px-2">
                        <Row>
                            <Col sm={12} lg={12}>
                                <br/>
                                <h5 className="m-0">Laporan Posisi Barang Dalam Proses (WIP)  </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} className="pt-2 pt-lg-0">
                                <Form onSubmit={handleButtonSearch}>
                                    <Row>
                                        <Col lg={3} md={5} sm={8} className="form-group mb-2 mb-lg-0">
                                            <label htmlFor="srcStartDate" className="form-label" style={{fontSize:"xx-small"}}>Pilih Tanggal: &nbsp;</label>
                                            <CompDateRangeWeek
                                                state={dateSelection}
                                                handleCallback={handleChangeDate}
                                                style={{width: "100%"}}
                                            /> 
                                        </Col>
                                        <Col sm={0} md={6} lg={8} className="form-group mb-2 mb-lg-0">
                                            &nbsp;
                                        </Col>
                                        <Col sm={4} md={1} lg={1} className="form-group mb-2 mb-lg-0 text-right">
                                            <br/>
                                            <Button className="btn btn-small btn-success swalDefaultAccess" onClick={handleButtonDownloadXLS} ><i className="right fas fa-download"></i></Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-2 pt-lg-2">
                                <div className="ag-theme-quartz text-center" style={{ height: "80vh", width: "100%", fontSize:"x-small" }} >
                                    <AgGridReact 
                                        ref={gridRef}
                                        onFilterChanged={(e) => onfiltered(e)}
                                        columnDefs={colDefWIP} 
                                        rowData={dataWIP} 
                                        defaultColDef={defaultColDef} 
                                        pinnedBottomRowData={pinnedBottomRowData}
                                        pagination={'true'}></AgGridReact>
                                </div>
                            </Col>
                        </Row>
                    </div>
            </div>
        </div>
   
    )
}